import React, { useEffect, useState, lazy, Suspense } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

import EngageBanner from "../components/box-engage-banner";
import BannerTickets from "../components/banner-tickets";
import BoxIniziativa from "../components/box-iniziativa";
import BoxSponsor from "../components/box-sponsor";
import BoxHelp from "../components/box-help";
import BoxNewsletter from "../components/box-newsletter";
import FormReport from "../components/form-report";

const Spline = lazy(() => import("@splinetool/react-spline"));

const ScaricaReport = ({ location }) => {
  const [year, setYear] = useState();
  useEffect(() => {
    setYear(new URLSearchParams(location.search).get("edizione"));
  }, [location.search]);
  return (
    <Layout>
      <Seo title={`Scarica report IIC`} description={`Report Intranet Italia Champions`} />

      <div className="box-intro box-intro--page-name">
        <Suspense>
          <Spline
            scene="https://prod.spline.design/LH1fpiv595HjQm6T/scene.splinecode"
            className="animation-circle"
          />
        </Suspense>
        <div className="box-intro__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12">
                <div className="box-intro__page-name">
                  <span>Scarica</span> report
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section section--grey-dark">
        <div className="box-article">
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-xl-8 offset-xl-1">
                <h2 className="box-article__title">Scarica il report IIC</h2>
                <div className="box-article__text">
                  <p>
                    Compila il form e riceverai nella e-mail indicata nel form il report IIC
                    selezionato.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FormReport year={year} />
      </section>

      <EngageBanner />

      <BannerTickets />

      <BoxIniziativa />

      <BoxSponsor />

      <BoxHelp />

      <BoxNewsletter />
    </Layout>
  );
};

export default ScaricaReport;
