import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { Link } from "./link";

const yearOption = [
  { value: 2022, label: "2022" },
  { value: 2021, label: "2021" },
  { value: 2020, label: "2020" },
  { value: 2019, label: "2019" },
];

const FormReport = ({ year }) => {
  const yearRef = useRef();

  const {
    register,
    handleSubmit,
    control: controlYear,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      year: yearOption[0].value,
      privacy: true,
    },
    mode: "onChange",
  });

  useEffect(() => {
    const numYear = parseInt(year);
    if (!isNaN(numYear) && yearOption.some((item) => item.value === numYear)) {
      setValue("year", numYear);
    }
  }, [setValue, year]);

  const [submitted, setSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const onSubmit = async (data) => {
    console.log(data);
    setSubmitError(false);
    try {
      const response = await fetch("/api/form-report", {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(
          `Error calling /api/form-report: ${response.status} ${response.statusText}`
        );
      }
      const result = await response.json();
      console.log("result: ", result);
      setSubmitted(true);
    } catch (err) {
      setSubmitError(true);
      console.error("Error form report: ", err);
    }
  };

  return (
    <div className="container-md">
      <div className="row">
        <div className="col-12 col-xl-10 offset-xl-1">
          {submitted ? (
            <div className="box-article">
              <div className="container-md">
                <div className="row">
                  <div className="col-12 text-center">
                    <h2 className="box-article__title">Grazie per il tuo ordine.</h2>
                    <div className="box-article__text">
                      <p>A breve riceverai una email con il link per scaricare il report.</p>
                    </div>
                    <div className="d-flex pb-5 justify-content-center">
                      <Link to="/" className="btn-link btn-link--small">
                        <span className="btn-link__circle"></span>
                        <span className="btn-link__text">
                          Torna alla <span className="d-block bold">homepage</span>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} className="form">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__placeholder-select">
                      Selezionare l'anno del report d'interesse *
                    </div>
                    <Controller
                      render={({ field: { value, onChange, ...other } }) => (
                        <Select
                          {...other}
                          options={yearOption}
                          isSearchable={false}
                          onChange={(option) => onChange(option.value)}
                          className="form__select"
                          classNamePrefix="select"
                          placeholder="Seleziona anno"
                          noOptionsMessage={() => "Nessun risultato"}
                          value={yearOption.find((option) => option.value === value)}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "#1a1b1f",
                              primary: "#333333",
                            },
                          })}
                          ref={(ref) => (yearRef.current = ref)}
                        />
                      )}
                      name="year"
                      control={controlYear}
                      onFocus={() => yearRef.current.focus()}
                      rules={{ required: true }}
                    />
                    {errors.year ? (
                      <span className="d-block form__error">
                        Selezionare l'anno del report di interesse.
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="text"
                        className="form__input-text"
                        name="name"
                        id="name"
                        placeholder=" "
                        {...register("name", {
                          required: {
                            value: true,
                            message: "Specificare un nome.",
                          },
                          pattern: {
                            value: /^[a-zA-Z\s]*$/,
                            message: "Specificare un nome (solo caratteri e/o spazi).",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="name">
                        Nome *
                      </label>
                    </div>
                    {errors.name ? (
                      <span className="d-block form__error">{errors.name.message}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="text"
                        className="form__input-text"
                        name="surname"
                        id="surname"
                        placeholder=" "
                        {...register("surname", {
                          required: {
                            value: true,
                            message: "Specificare un cognome.",
                          },
                          pattern: {
                            value: /^[a-zA-Z\s]*$/,
                            message: "Specificare un cognome (solo caratteri e/o spazi).",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="surname">
                        Cognome *
                      </label>
                    </div>
                    {errors.surname ? (
                      <span className="d-block form__error">{errors.surname.message}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="text"
                        className="form__input-text"
                        name="company"
                        id="company"
                        placeholder=" "
                        {...register("company", {
                          required: {
                            value: true,
                            message: "Specificare l'azienda.",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="company">
                        Azienda *
                      </label>
                    </div>
                    {errors.company ? (
                      <span className="d-block form__error">{errors.company.message}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="text"
                        className="form__input-text"
                        name="role"
                        id="role"
                        placeholder=" "
                        {...register("role", {
                          required: {
                            value: true,
                            message: "Specificare il ruolo.",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="role">
                        Ruolo *
                      </label>
                    </div>
                    {errors.role ? (
                      <span className="d-block form__error">{errors.role.message}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="email"
                        className="form__input-text"
                        name="email"
                        id="email"
                        placeholder=" "
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Specificare un indirizzo email.",
                          },
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Specificare un indirizzo email valido.",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="email">
                        Email *
                      </label>
                    </div>
                    <span className="d-block form__error">
                      Il report sarà inviato a questo indirizzo.
                    </span>
                    {errors.email ? (
                      <span className="d-block form__error">{errors.email.message}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form__group">
                    <div className="form__wrap-input-text">
                      <input
                        type="text"
                        className="form__input-text"
                        name="telefono"
                        id="telefono"
                        placeholder=" "
                        {...register("telefono", {
                          // required: {
                          //   value: true,
                          //   message: "Specificare un numero di telefono.",
                          // },
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Specificare un numero di telefono valido.",
                          },
                        })}
                      />
                      <label className="form__label" htmlFor="telefono">
                        Telefono
                      </label>
                    </div>
                    {errors.telefono ? (
                      <span className="d-block form__error">{errors.telefono.message}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form__group">
                    <label className="checkbox">
                      <div>
                        Confermo di aver preso visione dell'informativa sul trattamento dei dati
                      </div>
                      <input
                        type="checkbox"
                        name="privacy"
                        id="privacy"
                        className="checkbox__input"
                        {...register("privacy", {
                          required: {
                            value: true,
                            message: "Confermare la presa visione dell'informativa.",
                          },
                        })}
                      />
                      <span className="checkbox__mark"></span>
                    </label>
                    {errors.privacy ? (
                      <span className="d-block form__error">{errors.privacy.message}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn-link btn-link--submit"
                    >
                      <span className="btn-link__circle"></span>
                      <span className="btn-link__text">
                        Scarica il <span className="d-block bold">report</span>
                      </span>
                    </button>
                  </div>
                  {submitError && (
                    <div className="text-center mt-4">Ops, si è verificato un errore. Riprova.</div>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormReport;
